import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getMe, SaveUserDetails } from "../../Services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { setBalance, setUser } from "../../redux/User/UserSlice";
import SearchModal from "../Modals/SearchModal";
import searchIcon from "../../assets/images/svg/searchIcon.svg";
import LogoutImage from "../../assets/images/svg/logout.svg";
import { GetAllSports } from "../../redux/SportsRadar/SportsRadarSlice";
import { GetSideBarData, GetSportById } from "../../Services/SportsRadar";
import axios from "axios";

const AppNavbar = () => {
  const [showNav, setShowNav] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const User = useSelector((state) => state.User.User);
  const UserBalance = useSelector((state) => state.User.balance);
  const [showLogout, setShowLogout] = useState(false);
  const [openSearchModal, setopenSearchModal] = useState(false);
  const Cricket = useSelector((state) => state.Events.events);
  const [AllSportsCategory, setAllSportsCategory] = useState([])
  const logoutRef = useRef(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [allLeagues, setAllLeagues] = useState([]);
  const [userIP, setUserIP] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [events, setEvents] = useState([]);
  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      console.log("IP DAta", response)
      setUserIP(response.data.ip);
      getUserLocation(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };


  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      setUserLocation(response.data);
      console.log("country", response)
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };

  useEffect(() => {
    console.log(User)
    if (User) {
      getUserIP()
    }
  }, [User])

  const SaveUserIp = async () => {
    console.log("object")
    const data = {
      userId: User._id,
      ip: userIP
    }
    SaveUserDetails(data)
  }

  useEffect(() => {
    if (userIP) {
      SaveUserIp()
    }
  }, [userIP])

  const toggleLeague = (id) => {
    if (id == allLeagues) {
      setAllLeagues("")
    } else {
      setAllLeagues(id);
    }
  };

  const toggleSport = (sport) => {
    setSelectedSport((prevSport) => (prevSport === sport ? null : sport));
  };

  useEffect(() => {
    console.log("allLeagues", allLeagues)
  }, [allLeagues])


  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchEventsBySportId = async (sportId) => {
    const data = {
      date: await getCurrentDate(),
      sport_id: sportId
    }

    // await GetSportById(data).then((res) => {
    //   const uniqueSeriesNames = [...new Set(res?.sport_events.map((event) => event.season.name))];
    //   setEvents(uniqueSeriesNames);
    // })
  };

  function Logout() {
    localStorage.clear();
    setShowNav(false);
    dispatch(setUser(null));
    window.location.reload();
  }

  const getUser = async () => {
    await getMe().then((res) => {
      if (res.status) {
        setCurrentUser(res.data);
        dispatch(setUser(res.data));

      } else {
        localStorage.clear("sfwa_tkn");
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("sfwa_tkn");
    setIsLogin(!!token);
    if (token) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (selectedSport) {

      fetchEventsBySportId(selectedSport?.id);
    }
  }, [selectedSport]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(setBalance(currentUser?.balance))
  }, [currentUser])

  useEffect(() => {
  }, [UserBalance])

  function getGreeting() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      return "Good Morning";
    } else if (hour >= 12 && hour < 18) {
      return "Good Afternoon";
    } else if (hour >= 18 && hour < 22) {
      return "Good Evening";
    } else {
      return "Good Night";
    }
  }
  useEffect(() => {
    dispatch(GetAllSports())
  }, [])

  useEffect(() => {
    GetSideBarData().then((res) => {
      console.log(res)
      setAllSportsCategory(res)
    })
  }, [])
  return (
    <nav>
      <div className="container position-relative">
        <div
          onClick={() => setShowNav(false)}
          className={
            showNav === false
              ? "body-overlay-false position-fixed"
              : "body-overlay bg_clr_green position-fixed"
          }
        ></div>
        <ul className={showNav === false ? "nav-hidden" : "nav-open ps-0 pb-4"}>
          <div className="vh-100 d-flex flex-column justify-content-between">
            <div className="">
              <div className="d-flex align-items-center justify-content-between gap-3 mt-4 px-3">
                <div>
                  <div>
                    {isLogin && (
                      <div>
                        {User?.login_Type !== "google" ? (
                          <div>
                            <p className="mb-0 text-white fw-bolder fs_18">
                              {User.fullName + " " + User.lastName}
                            </p>
                          </div>
                        ) : (
                          <p className="mb-0 text-white fw-bolder fs_18">
                            {User?.username}
                          </p>
                        )}
                      </div>
                    )}
                    <span className="text-white fs_14">{getGreeting()}</span>
                  </div>
                </div>
                <div
                  onClick={() => setShowNav(false)}
                  className="hamburger d-block flex-column"
                >
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </div>
              <div>
                <div className="px-3 mt-3">
                  <div
                    className="search-input-div rounded-2 bg_clr_dark_grey_3 w-100 d-flex align-items-center justify-content-between pe-3 ps-3"
                    onClick={() => {
                      setopenSearchModal(true);
                    }}
                  >
                    <div
                      className="bg-transparent border-0 search-input ff_heebo fs_14 text-white opacity-26"
                      type="text"
                    >
                      Search Your Game
                    </div>
                    <img
                      style={{ width: "18px" }}
                      src={searchIcon}
                      alt="searchicon"
                    />
                  </div>
                </div>
                <h2 className="mt-3 ff_inter clr_soil fw-800 fs_22 ps-4">
                  All Sports
                </h2>
                <div className="mt-3">
                  {AllSportsCategory.map((sport, index) => (
                    <div key={index}>
                      <div
                        className="d-flex align-items-center gap-2 ps-3 mt-3 ps-4"
                        onClick={() => toggleSport(sport)}
                      >
                        {/* <img src={sport.img} alt={sport.name} />   */}
                        <p className="mb-0 ff_inter fs_16 text-white fw-medium cursor-pointer">
                          {sport?.name + ` (${sport.tournament.length})`}
                        </p>
                      </div>
                      {selectedSport === sport && (
                        <div className="mt-2 bg_clr_dark_grey_3">
                          <ul className="px-3 pb-3 pt-2">
                            {sport.tournament.length > 0 ?
                              sport?.tournament?.map((seriesEvents, index) => (
                                <li className="pt-2" key={index}>
                                  <button
                                    onClick={() => toggleLeague(seriesEvents?.name)}
                                    className="ff_inter fw-medium fs_14 border-0 bg-transparent text-white d-flex align-items-center  text-start justify-content-between w-100"
                                  >
                                    {seriesEvents?.name + ` `}
                                  </button>
                                  <div
                                    className={
                                      allLeagues == seriesEvents?.name
                                        ? "d-block ps-4"
                                        : "d-none"
                                    }
                                  >
                                    {seriesEvents.matche.map((event, index) => (
                                      <p
                                        key={index}
                                        className="clr_grey_3 ff_inter fs_14 mb-1 cursor-pointer"
                                        onClick={() => {
                                          if (sport.name == "Cricket") {
                                            navigate("/sport_details/" + event?.id + "/" + sport?.name +"/" + event.match_start_time +"/" + event.betfair_event_id)
                                          } else {
                                            navigate("/sport_details/" + event?.id + "/" + sport?.name)
                                          }
                                          setShowNav(false)
                                        }}
                                      >
                                        {event?.name}
                                      </p>
                                    ))}
                                  </div>
                                </li>
                              )) : <span></span>}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {isLogin && (
              <div className="px-4 mt-3">
                <button
                  style={{ borderRadius: "2px" }}
                  className="text-white ff_heebo fw-medium fs_15 bg_clr_green px-3 py-2 border-0 w-100"
                  onClick={Logout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </ul>
        <div className="d-flex align-items-center justify-content-between pt-3 ">
          <div className="d-flex align-items-center gap-2">
            <div
              onClick={() => setShowNav(true)}
              className="hamburger d-block flex-column"
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <Link to={"/"}>
              <h2 className="mb-0 clr_green ff_inter fst-italic fw-900 fs_28">
                45<span className="text_x">X</span>
              </h2>
            </Link>
          </div>

          <div className="d-flex align-items-center gap-1">
            {!isLogin ? (
              <>
                <Link
                  to={"/login"}
                  className="bg-transparent border-0 text-white ff_heebo fw-medium fs_15"
                >
                  Sign In
                </Link>
                <Link to={"/login"}>
                  <button
                    style={{ borderRadius: "2px" }}
                    className="text-white ff_heebo fw-medium fs_15 bg_clr_green px-3 py-2 border-0"
                  >
                    Register
                  </button>
                </Link>
              </>
            ) : (
              <div className="d-flex gap-2 align-items-center w-100">
                <div className="price_container p-1 rounded d-flex align-items-center">
                  <div className="d-flex flex-column ps-2 pe-3 ">
                    <span className="currency ff_heebo fw-medium fs_12 text-uppercase">
                      inr
                    </span>
                    <span className="text-white ff_heebo fw-medium fs_12">
                      {Math.round(Number(UserBalance || 0)).toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <button
                      style={{ borderRadius: "2px" }}
                      className="text-white ff_heebo fw-medium fs_12 bg_clr_green px-3 py-2 border-0 d-flex"
                      onClick={() => {
                        navigate("/deposit");
                      }}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="position-relative" ref={logoutRef}>
                  <div className="search-icon p-2 rounded cursor-pointer">
                    <img
                      src={searchIcon}
                      height={25}
                      width={25}
                      onClick={() => {
                        setopenSearchModal(true);
                      }}
                    />
                  </div>
                  {showLogout && (
                    <div className="bg_clr_dark_grey_3 logoutbtn py-2 px-3 rounded position-absolute end-0 my-2">
                      <div>
                        <div
                          className="d-flex align-items-center gap-2"
                          onClick={Logout}
                        >
                          <img src={LogoutImage} />
                          <span className="text-white">Logout</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

        </div>
        <SearchModal
          open={openSearchModal}
          close={() => {
            setopenSearchModal(false);
          }}
        />
      </div>
    </nav>
  );
};

export default AppNavbar;
