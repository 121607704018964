import React, { useState, useRef } from "react";
import AppNavbar from "../home/AppNavbar";
import { Link } from "react-router-dom";
import pulsh_icon from "../../assets/images/svg/pulsh_icon.svg";
import copy_icon from "../../assets/images/svg/copy_icon.svg";
import qrcode_icon from "../../assets/images/svg/qrcode_icon.svg";
import upload_icon from "../../assets/images/svg/uploadIcon1.svg";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import sucessIcon from "../../assets/images/png/success.png";
import FailIcon from "../../assets/images/png/close.png";

import minus from "../../assets/images/svg/minus.svg";
import { uploadScreenShot } from "../../Services/PaymentService";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { QRCodeCanvas } from "qrcode.react";

const Deposit = () => {
  const [activePrice, setActivePrice] = useState(0);
  const [value, setValue] = useState(50);
  const [UPIamount, setUPTAmount] = useState("");
  const [bankamount, setBankAmount] = useState("");
  const [upiLoading, setUpiLoading] = useState("");
  const [bankLoading, setBankLoading] = useState("")
  const [money, setMoney] = useState([
    {
      price: "$50",
      value: 50,
    },
    {
      price: "$100",
      value: 100,
    },
    {
      price: "$200",
      value: 200,
    },
    {
      price: "$500",
      value: 500,
    },
  ]);
  const [selectedFileUPI, setSelectedFileUPI] = useState(null);
  const [selectedFileIMPS, setSelectedFileIMPS] = useState(null);
  const [selectedFileUPIImage, setselectedFileUPIImage] = useState(null);
  const [selectedFileIMPSImage, setselectedFileIMPSImage] = useState(null);
  const [successfailModal, setSuccessfailModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(true);
  const fileInputRefUPI = useRef(null);
  const fileInputRefIMPS = useRef(null);

  const handleUploadClickUPI = () => {
    fileInputRefUPI.current.click();
  };

  const handleUploadClickIMPS = () => {
    fileInputRefIMPS.current.click();
  };

  const handleFileChangeUPI = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size should not exceed 2MB");
        return;
      }
      setSelectedFileUPI(URL.createObjectURL(file));
      setselectedFileUPIImage(file);
    }
  };

  const handleFileChangeIMPS = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size should not exceed 2MB");
        return;
      }
      setSelectedFileIMPS(URL.createObjectURL(file));
      setselectedFileIMPSImage(file);
    }
  };

  const handlePayNowClick = (method) => {
    if (method === "UPI" && selectedFileUPI) {
      console.log("Pay Now with UPI file:", selectedFileUPI);
    } else if (method === "IMPS" && selectedFileIMPS) {
      console.log("Pay Now with IMPS file:", selectedFileIMPS);
    } else {
      console.log("Pay Now without file");
    }
  };

  const handleUploadUpiUpload = async () => {
    setUpiLoading(true)
    if (UPIamount <= 0) {
      toast.error("Please enter a valid amount");
      setUpiLoading(false)
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFileUPIImage);
    formData.append("payment_method", "UPI");
    formData.append("amount", UPIamount);

    await uploadScreenShot(formData).then((res) => {
      if (res) {
        setSuccessfailModal(true);
        setPaymentStatus(true);
        setSelectedFileUPI(null);
        setUPTAmount("")
        setselectedFileUPIImage(null);
        setUpiLoading(false)
      } else {
        setSuccessfailModal(true);
        setPaymentStatus(false);
        setUpiLoading(false)

      }
    });
  };

  const handleUploadBankUpload = async () => {
    setBankLoading(true)
    if (bankamount <= 0) {
      toast.error("Please enter a valid amount");
      setBankLoading(false)
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFileIMPSImage);
    formData.append("payment_method", "IMPS");
    formData.append("amount", bankamount);
    await uploadScreenShot(formData).then((res) => {
      if (res) {
        setSuccessfailModal(true);
        setPaymentStatus(true);
        setSelectedFileIMPS(null);
        setBankAmount("")
        setselectedFileIMPSImage(null);
        setBankLoading(false)
      } else {
        setSuccessfailModal(true);
        setPaymentStatus(false);
        setBankLoading(false)

      }
    });
  };

  const adjustAmount = (amount) => {
    const numericAmount = parseFloat(amount);
    // Check if the amount is a whole number
    if (Number.isInteger(numericAmount)) {
      // Generate a random fractional number between 0.02 and 0.20
      const randomFraction = (Math.random() * (0.20 - 0.02) + 0.02).toFixed(2);
      return numericAmount - parseFloat(randomFraction);
    }
    return numericAmount; // Return as is if it's not a whole number
  };

  const generateUPIURL = () => {
    const upiID = "7056913340@ptaxis"
    const adjustedAmount = adjustAmount(UPIamount); // Adjust the amount
    return `upi://pay?pa=${upiID}&pn=45X&am=${adjustedAmount.toFixed(2)}&cu=INR`;
  };

  return (
    <div>
      <ToastContainer />
      <div className="container pb-4">
        <div className="d-flex align-items-center justify-content-between mt-4 gap-3">
          <Link className="w-100" to={"/deposit"}>
            <button
              style={{ height: "55px" }}
              className="ff_heebo text-white bg_clr_green rounded-1 border-0 w-100"
            >
              Deposit
            </button>
          </Link>
          <Link className="w-100" to={"/withdrawal"}>
            <button
              style={{ height: "55px" }}
              className="ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100"
            >
              Withdrawal
            </button>
          </Link>
        </div>
        <div className="d-flex deposit flex-column mt-3">
          <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
            Enter Amount
          </label>
          <div className="d-flex mt-2 px-3 align-items-center w-100 justify-content-between bg-transparent border border-1 rounded-1 outline_none text-white">
            <img
              src={minus}
              alt="pulsh_icon"
              onClick={() => {
                setValue(Math.max(0, value - 1));
              }}
            />
            <input
              className="p-3 bg-transparent border-0 w-100 outline_none text-white ff_heebo fw-medium fs_16"
              type="number"
              placeholder="enter amount"
              value={value}
              onChange={(e) => {
                const val = parseInt(e.target.value, 10);
                setValue(val >= 0 ? val : "");
              }}
            />
            <img
              src={pulsh_icon}
              alt="pulsh_icon"
              onClick={() => {
                setValue(value + 1);
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2 align-items-center mt-3">
          {money.map((value, index) => (
            <button
              key={index}
              onClick={() => {
                setActivePrice(index);
                setValue(value.value);
              }}
              style={{ borderRadius: "2px" }}
              className={`px-2 py-1 mb-0 ff_heebo fs_10 text-white border-0 ${activePrice === index ? "bg_clr_green" : "bg_clr_dark_grey_3"
                }`}
            >
              {value.price}
            </button>
          ))}
        </div>
        <p className="text-center ff_heebo mt-3 mb-0 fs_10 fw-medium clr_grey_3">
          Intent Pay (Only For Mobile)
          <br />
          Select amount - Click PayNow - Pay using Intent
          <br />
          Applications - And Get your Points
        </p>
        <button className=" bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_heebo w-100 mt-4">
          Pay Now
        </button>
        <div>
          <div className=" bg_clr_dark_grey_3 rounded-2 p-3 mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className=" fs_24 fw-bold ff_heebo text-white mb-0">UPI</h5>
                <h6 className=" fs_16 fw-normal ff_roboto text-white mb-0 mt-2">
                  UPI Transfer
                </h6>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <img src={copy_icon} alt="copy_icon" />
                  <p className="mb-0 fs_12 ff_roboto text-white">
                    323276855230136@cnrb
                  </p>
                </div>
              </div>
              {UPIamount ? <div className="p-2 bg-white rounded">
                <QRCodeCanvas value={generateUPIURL()}  className="w-100" width={50} />
              </div> : <></>}
            </div>
            <div className="mt-3">
              <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
                Enter Amount
              </label>
              <div className="d-flex mt-2 px-3 align-items-center w-100 justify-content-between bg-transparent border border-1 rounded-1 outline_none text-white">
                <input
                  className="p-3 bg-transparent border-0 w-100 outline_none text-white ff_heebo fw-medium fs_16"
                  type="number"
                  placeholder="enter amount"
                  value={UPIamount}
                  onChange={(e) => {
                    const val = parseFloat(e.target.value);
                    setUPTAmount(val >= 0 ? val : "");
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
                Upload Screenshot
              </label>
              <input
                type="file"
                ref={fileInputRefUPI}
                onChange={handleFileChangeUPI}
                style={{ display: "none" }}
              />
              <div
                className="bg-transparent mt-2 d-flex align-items-center justify-content-center border border-1 rounded-1 p-5 flex-column"
                onClick={handleUploadClickUPI}
              >
                {selectedFileUPI ? (
                  <div>
                    <img src={selectedFileUPI} alt="Uploaded file" className="w-100" />
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={upload_icon} alt="upload_icon" />
                    <p className="mt-2 ff_roboto fs_16 fw-normal text-white mb-0">
                      Drop Your File Here
                    </p>
                  </div>
                )}
              </div>
              {!upiLoading ? <button
                onClick={handleUploadUpiUpload}
                className=" bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_heebo w-100 mt-4"
              >
                Pay Now
              </button> : <button
                className=" bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_heebo w-100 mt-4"
              >
                <Spinner size="sm" />
              </button>}
            </div>
          </div>
          <div className=" bg_clr_dark_grey_3 rounded-2 p-3 mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className=" fs_24 fw-bold ff_heebo text-white mb-0">IMPS</h5>
                <h6 className=" fs_16 fw-normal ff_roboto text-white mb-0 mt-2">
                  IMPS Transfer
                </h6>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <img src={copy_icon} alt="copy_icon" />
                  <p className="mb-0 fs_12 ff_roboto text-white">
                    10465799495
                  </p>
                </div>
              </div>
              <img src={qrcode_icon} alt="qrcode_icon" />
            </div>
            <div className="mt-3">
              <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
                Enter Amount
              </label>
              <div className="d-flex mt-2 px-3 align-items-center w-100 justify-content-between bg-transparent border border-1 rounded-1 outline_none text-white">

                <input
                  className="p-3 bg-transparent border-0 w-100 outline_none text-white ff_heebo fw-medium fs_16"
                  type="number"
                  placeholder="enter amount"
                  value={bankamount}
                  onChange={(e) => {
                    const val = parseFloat(e.target.value);
                    setBankAmount(val >= 0 ? val : "");
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
                Upload Screenshot
              </label>
              <input
                type="file"
                ref={fileInputRefIMPS}
                onChange={handleFileChangeIMPS}
                style={{ display: "none" }}
              />
              <div
                className="bg-transparent mt-2 d-flex align-items-center justify-content-center border border-1 rounded-1 p-5 flex-column"
                onClick={handleUploadClickIMPS}
              >
                {selectedFileIMPS ? (
                  <div>
                    <img src={selectedFileIMPS} alt="Uploaded file" className="w-100" />
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={upload_icon} alt="upload_icon" />
                    <p className="mt-2 ff_roboto fs_16 fw-normal text-white mb-0">
                      Drop Your File Here
                    </p>
                  </div>
                )}
              </div>
              {!bankLoading ? <button
                onClick={handleUploadBankUpload}
                className=" bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_heebo w-100 mt-4"
              >
                Pay Now
              </button> :
                <button
                  className=" bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_heebo w-100 mt-4"
                >
                  <Spinner size="sm" />
                </button>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={successfailModal}
        onHide={() => setSuccessfailModal(false)}
        className="bg_modal"
      >
        <Modal.Body className="p-0 m-0">
          <div className="p-5 text-center bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div onClick={() => setSuccessfailModal(false)} className=" position-absolute end-0 top-0">
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <img
              src={paymentStatus ? sucessIcon : FailIcon}
              alt="status icon"
              width={64}
              height={64}
            />
            <h4 className="mt-4 ff_heebo fw-bold text-white">
              {paymentStatus ? "Your deposit request submitted successfully" : "Payment Failed"}
            </h4>
            <p className="mt-2 ff_roboto text-white">
              {paymentStatus
                ? "Amount will added to your account after a short time peroid, stay with us"
                : "Your payment could not be processed. Please try again."}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Deposit;
