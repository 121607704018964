import React, { useEffect, useState } from "react";
import BottomNav from "./bottomnav/BottomNav";
import { GetCasnioHistory, GetSportHistory } from "../Services/HistoryServices";
import { getLocalDate, getLocalTime, removeUnwanted } from "../Services/Common";
import filterIcon from "../assets/images/png/filter.png"
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const BetHistoryComponents = () => {
  const [activeTab, setActiveTab] = useState("casnio"); // Initial tab
  const [CasnioHistory, setCasnioHistory] = useState([]);
  const [SportsHistory, setSportsHistory] = useState([]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [selectedSport, setSelectedSport] = useState("Soccer"); // Default category set to "Soccer"
  const Categoires = useSelector((state) => state.SportsRadar.allSports);

  useEffect(() => {
    console.log(Categoires);
  }, [Categoires]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getCasnioHistory = async () => {
    const data = {
      status: "", // credit and debit
      start_date: "",
      end_date: "",
      page: 1,
      limit: 10,
    };
    await GetCasnioHistory(data).then((res) => {
      console.log(res);
      if (res) {
        setCasnioHistory(res?.data);
      }
    });
  };

  const getSportBetHistory = () => {
    GetSportHistory(selectedSport).then((res) => {
      console.log(res);
      if (res?.bets) {
        setSportsHistory(res?.bets);
      } else {
        setSportsHistory([]);
      }
    });
  };

  useEffect(() => {
    switch (activeTab) {
      case "casnio":
        getCasnioHistory();
        break;
      case "sports":
        getSportBetHistory();
        break;
      default:
        break;
    }
  }, [activeTab , selectedSport]);

  return (
    <div>
      <div className="container py-3">
        <h5 className="fs_16 fw-medium ff_heebo text-white">Bets History</h5>

        <div className="d-flex align-items-center justify-content-between gap-3 my-3">
          <button
            className={`py-2 ff_heebo ${activeTab === "casnio"
              ? "clr_green bg_clr_green green_border text-white"
              : "bg-transparent green_border clr_green"
              } rounded-1 w-100`}
            onClick={() => handleTabClick("casnio")}
          >
            Casino
          </button>
          <button
            className={`py-2 ff_heebo ${activeTab === "sports"
              ? "clr_green bg_clr_green green_border text-white"
              : "bg-transparent green_border clr_green"
              } rounded-1 w-100`}
            onClick={() => handleTabClick("sports")}
          >
            Sports
          </button>
        </div>

        <div className="mt-3">
          {activeTab === "casnio" && (
            <div className="my-4">
              {CasnioHistory.length > 0 ? (
                <div className="mt-4">
                  {CasnioHistory?.map((item, index) => (
                    <div
                      key={index}
                      className="bg_clr_dark_grey_3 p-3 rounded-1 mt-3"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white text-uppercase">
                            {removeUnwanted(item?.game_code)}
                          </h5>
                          <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                            {getLocalDate(item.createdAt) +
                              " " +
                              `(${getLocalTime(item.createdAt)})`}
                          </p>
                        </div>

                        {item.status === "debit" ? (
                          <span className="fs_16 fw-medium ff_heebo clr_red">
                            - {item?.amount}
                          </span>
                        ) : (
                          <span className="fs_16 fw-medium ff_heebo clr_green">
                            + {item?.amount}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h4 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
                    No Bet History Found
                  </h4>
                </div>
              )}
            </div>
          )}

          {activeTab === "sports" && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <p className="text-white mb-0">Current Bets</p>
                <div className="position-relative">
                  <Form.Select
                    aria-label="Select Sport"
                    className="bg_clr_dark_grey_3 text-white"
                    value={selectedSport}
                    onChange={(e) => setSelectedSport(e.target.value)}
                  >
                    {Categoires?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div onClick={() => setFilterDropDown(!filterDropDown)}>
                    {/* <img src={filterIcon} width={20} /> */}
                  </div>
                  {filterDropDown && (
                    <div className="pt-0 position-absolute bg-white p-3 mt-2 rounded right-0">
                      <div className="mt-2">
                        {/* <p className="text-nowrap mb-0">All Bets</p> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {SportsHistory.length > 0 ? (
                <div className="my-4">
                  {SportsHistory?.map((item, index) => (
                    <div
                      key={index}
                      className={`bg_clr_dark_grey_3 p-3 rounded-1 mt-3 ${
                        item.bet_type === "lay" ? "lay-border" : "back-border"
                      }`}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white text-uppercase">
                            Bet Amount: {item?.amount}
                          </h5>
                          <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                            {item.team_name}
                          </p>
                          <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                            {item.bet_title}, {item.sports_type !== "Cricket" ? `Bhav ${item.bhav}` : `Odd: ${item.odd}`} 
                          </p>
                          <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                            {getLocalDate(item.createdAt)}
                          </p>
                        </div>
                        <div>
                          <div className="text-white">
                            Return amount:
                            <span className="fs_16 fw-medium ff_heebo clr_green">
                              {Math.floor(item?.return_amount)}
                            </span>
                          </div>
                          <p
                            className={`mb-0 fs_11 ff_heebo mt-1 ${
                              item?.bet_status === "won"
                                ? "text-success"
                                : item.bet_status === "loss"
                                ? "text-danger"
                                : "text-white"
                            } text-capitalize`}
                          >
                            Status: {item?.bet_status}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h4 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
                    No Bet History Found
                  </h4>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default BetHistoryComponents;
