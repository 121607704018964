import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeTest = () => {
  const [upiID, setUpiID] = useState('');
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');

  // Function to add a random fractional amount between 0.02 and 0.20
  const adjustAmount = (amount) => {
    const numericAmount = parseFloat(amount);
    // Check if the amount is a whole number
    if (Number.isInteger(numericAmount)) {
      // Generate a random fractional number between 0.02 and 0.20
      const randomFraction = (Math.random() * (0.20 - 0.02) + 0.02).toFixed(2);
      return numericAmount - parseFloat(randomFraction);
    }
    return numericAmount; // Return as is if it's not a whole number
  };

  // UPI URL format with adjusted amount
  const generateUPIURL = () => {
    const adjustedAmount = adjustAmount(amount); // Adjust the amount
    return `upi://pay?pa=${upiID}&pn=${name}&am=${adjustedAmount.toFixed(2)}&cu=INR`;
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Generate UPI QR Code</h1>

      <div>
        <label>UPI ID: </label>
        <input
          type="text"
          value={upiID}
          onChange={(e) => setUpiID(e.target.value)}
          placeholder="Enter UPI ID"
        />
      </div>

      <div>
        <label>Name: </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter Name"
        />
      </div>

      <div>
        <label>Amount: </label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter Amount"
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        {upiID && amount ? (
          <>
            <h3>QR Code:</h3>
            <QRCodeCanvas value={generateUPIURL()} />
          </>
        ) : (
          <p>Please enter UPI ID and amount to generate QR code</p>
        )}
      </div>
    </div>
  );
};

export default QRCodeTest;
