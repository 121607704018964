import React, { useState } from "react";
import { Form } from "react-bootstrap";
import google_icon from "../../assets/images/svg/google_icon.svg";
import facebook_icon from "../../assets/images/svg/facebook_icon.svg";

import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { LoginWithGoogle } from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";
import { countryList } from "../../Services/Common";
import BlockedModal from "../Modals/BlockedModal";

const SocialLogin = () => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [block, setBlock] = useState(false)

  const fetchUserInfo = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        "Failed to fetch user information. Please try again later."
      );
    }
  };

  const handleGoogleLogin = () => {
    googleLogin();
  };

  const handleFacebookLogin = (response) => {
    responseFacebook(response);
  };

  const googleLogin = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_KEY,
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await fetchUserInfo(tokenResponse.access_token);
        console.log(userInfo)
        const { email, name, family_name, given_name } = userInfo;
        const data = {
          country: "IN",
          email: email,
          login_Type: "google",
          firstName: given_name,
          lastName: family_name,
        };
        await LoginWithGoogle(data).then((res) => {
          if (res.status) {
            localStorage.setItem("sfwa_tkn", res.token);
            window.location.reload();
          } else {
            setBlock(true)
          }
        });
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    onFailure: (error) => {
      console.error("Google login failed:", error);
    },
  });

  const responseFacebook = (response) => {
    console.log(response);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between flex-column">
        <div>
          <div className="pt-4 w-100 ff_roboto d-flex flex-column">
            <label className="clr_grey ff_roboto" htmlFor="">
              Choose Country <span className="clr_red">*</span>
            </label>
            <Form.Select
              size="md"
              className=" bg_clr_dark_grey_3 clr_grey border-0 mt-3 p-3"
            >
              <option value={""}>Please select country</option>
              {countryList.map((item, index) => {
                return (
                  <option value={item.code}>{item.name}</option>
                )
              })}
            </Form.Select>
          </div>
          <div className="d-flex flex-column gap-3">
            <div
              onClick={handleGoogleLogin}
              className="custom_border rounded-1 d-flex justify-content-center align-items-center mt-4 p-3 gap-2 fs_13 ff_roboto fw-medium"
            >
              <img src={google_icon} alt="google_icon" />
              <p className="text-white mb-0 ff_roboto">Continue with Google</p>
            </div>
            <FacebookLogin
              icon={<img src={facebook_icon} alt="Facebook" />}
              textButton="Continue with Facebook"
              appId={process.env.REACT_APP_FB_APP_KEY}
              fields="name,email,picture"
              callback={handleFacebookLogin}
            />
          </div>
          <p className="fs_12 clr_grey mt-3 ff_roboto">
            The currency you select will affect which payment methods you can
            use to make deposits and withdrawals. After you've registered, you
            will not be able to change your account currency.
          </p>
        </div>
        <div className="">
          {/* <button className=" w-100 mt-4 fs_16 text-white ff_roboto bg_clr_green border-0 p-3 rounded-1">
            Continue
          </button> */}
          <button className=" w-100 mt-4 fw-bold fs_16 text-white ff_roboto border-0 bg_clr_dark_grey_3 p-3 rounded-1">
            <span className="fw-normal clr_green">Log in with</span> Demo ID
          </button>
        </div>
      </div>
      <BlockedModal open={block} close={() => {
        setBlock(false)
      }} />
    </div>
  );
};

export default SocialLogin;
