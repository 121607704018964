import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cricket from '../../assets/images/svg/cricketStumps.svg';
import { useDispatch, useSelector } from 'react-redux';
import EventsInfo from '../betting/EventsInfo.';
import socketService from '../../Services/SocketService';
import axios from 'axios';
import SportsRadarBettingModal from '../betting/SportsRadarBettingModal';
import { addSportsRadarBet } from '../../redux/User/UserSlice';
import { toast } from 'react-toastify';

function OtherSportsEventDetails() {
    const { sport, id } = useParams();
    const SelectedEvent = useSelector((state) => state.SelectedEvent.events);
    const [match, setMatch] = useState();
    const [previousMatch, setPreviousMatch] = useState(null); // Store previous match data
    const [rippleIndexes, setRippleIndexes] = useState({}); // Track ripple state for each outcome
    const [BettingModal, setBettingModal] = useState(false)
    const dispatch = useDispatch()
    const GetStaticData = async () => {
        const res = await axios({
            url: process.env.REACT_APP_BASEURL + "/getRedarSportsOdds",
            method: "POST",
            data: {
                match_id: id
            }
        })
        console.log("res ====>", res.data)
        setMatch(res.data.data)
    }

    useEffect(() => {
        if (id) {
            socketService.connect();

            socketService.emit("join", { userId: id }, (error, message) => {
                if (error) {
                    console.log(`Error joining: ${error}`);
                } else {
                    console.log(message);
                }
            });

            const handleBookmark = (data) => {
                console.log(data);

                // Compare new match data with the previous one
                if (JSON.stringify(data) !== JSON.stringify(previousMatch)) {
                    setPreviousMatch(match); // Store the current match as the previous one
                    setMatch(data);          // Update the match with the new data
                    triggerRippleEffect(data); // Trigger ripple effect when match data changes
                }
            };

            socketService.on("sportsradar", handleBookmark);

            return () => {
                socketService.disconnect();
            };
        }
    }, [id, match, previousMatch, socketService]);

    const triggerRippleEffect = (newMatch) => {
        if (newMatch?.markets) {
            const newRippleIndexes = {};

            newMatch.markets.forEach((market, marketIndex) => {
                market.outcomes.forEach((outcome, outcomeIndex) => {
                    newRippleIndexes[`${marketIndex}-${outcomeIndex}`] = true;
                });
            });

            setRippleIndexes(newRippleIndexes);

            // Remove ripple effect after animation duration
            setTimeout(() => {
                setRippleIndexes({});
            }, 600); // 600ms matches the CSS animation duration
        }
    };

    useEffect(() => {
        if (id) {
            GetStaticData()
        }
    }, [id])

    function getTwoWords(text) {
        const words = text.split(" "); // Split the string into an array of words
        if (words.length > 2) {
            return words.slice(0, 2).join(" ") + "..."; // Return the first two words with "..."
        }
        return text; // If there are 2 or fewer words, return the original text
    }
    return (
        <div className='container p-3'>
            <div className="">
                <div className="d-flex align-items-center gap-2">
                    <img src={cricket} alt="stumps" />
                    <p className="mb-0 ff_inter fs_19 text-white fw-medium">
                        {sport}
                    </p>
                </div>
            </div>
            <EventsInfo event={SelectedEvent} />
            <div className='gap-3 overflow-auto matches-box-div'>
                {match && match?.markets?.map((item, marketIndex) => {
                    return (
                        <div className='matches-div rounded-2 px-3 py-4 h-100 mt-3' key={marketIndex}>
                            <p className='text-white'>
                                {item.name}
                            </p>
                            <div >
                                {item?.outcomes?.map((odds, outcomeIndex) => {
                                    const rippleClass = rippleIndexes[`${marketIndex}-${outcomeIndex}`] ? 'ripple' : '';

                                    return (
                                        <div
                                            className={`bg_clr_dark_blue_2 betting-info-div my-2 rounded-2 w-100 d-flex flex-column align-items-center justify-content-center text-white ${rippleClass}`}
                                            key={outcomeIndex}
                                            onClick={() => {
                                                if (odds.odds !== "NaN") {
                                                    setBettingModal(true)
                                                    dispatch(addSportsRadarBet({
                                                        bhav: odds.odds,
                                                        seletedItem: odds.name,
                                                        team_name: match.name,
                                                        tournament_name: "India Test",
                                                        match_id: id,
                                                        odd_id: odds.id,
                                                        sports_type: sport,
                                                        market_id: item.market_id
                                                    }))
                                                } else {
                                                    toast.error("You can bet on this odd")
                                                }
                                            }}
                                        >
                                            <div className='d-flex text-center flex-column'>
                                                <p className='text-red mb-0'>
                                                    {getTwoWords(odds.name)}
                                                </p>
                                                <div className=''>
                                                    <p className='mb-0'>{odds.odds == "NaN" ? "-" : odds.odds}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div
                onClick={() => setBettingModal(false)}
                className={`body-overlay ${BettingModal === true ? "d-block" : "d-none"}`}
            ></div>
            <SportsRadarBettingModal active={BettingModal} setIsBettingFalse={() => { setBettingModal(false) }} />
        </div>
    );
}

export default OtherSportsEventDetails;
