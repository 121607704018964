import React, { useEffect, useState } from "react";
import multiTicket from "../../assets/images/svg/multiTicket.svg";
import plus from "../../assets/images/svg/plus.svg";
import cross from "../../assets/images/svg/whiteCrossIcon.svg";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { clearAllBet, clearAllSportsRadarBet, deleteBet, deleteSportsRadarBet } from "../../redux/User/UserSlice";
import { createSportsBet } from "../../Services/bettingService";
import { toast, ToastContainer } from "react-toastify";

const SportsRadarBettingModal = ({
    active,
    setIsBettingFalse,
    selectedBet,
}) => {
    const UserBalance = useSelector((state) => state.User.balance);
    const [lowbalance_alert, setLowBalanceAlert] = useState(false)
    const [inputValues, setInputValues] = useState({});
    const dispatch = useDispatch();
    const User = useSelector((state) => state.User.User);
    const AllBets = useSelector((state) => state.User.sportsRadarBets);
    const [loading, setLoading] = useState(false)
    const [FunModeModal, setFunModeModal] = useState(false)
    const [betModal, setBetModal] = useState(false)
    const [valueChangeModal, setValueChangeModal] = useState(false)

    // const handlePlaceBet = async () => {
    //     setLoading(true);
    //     if (User == null) {
    //         setFunModeModal(true);
    //         setLoading(false);
    //     } else {
    //         const totalBetAmount = Object.values(inputValues).reduce((acc, val) => acc + parseFloat(val || 0), 0);
    //         if (UserBalance < totalBetAmount) {
    //             setLowBalanceAlert(true);
    //             setLoading(false);
    //             return;
    //         }

    //         const bets = AllBets.map((item, index) => ({
    //             amount: inputValues[index],
    //             match_id: item.match_id,
    //             team_name: "India",
    //             bet_title: item.seletedItem,
    //             return_amount: (inputValues[index] * item?.bhav).toFixed(2),
    //             tournament_name: "India Test",
    //             bhav: item.bhav
    //         }));

    //         await createSportsBet({ bets }).then((res) => {
    //             setLoading(false);
    //             console.log("placing bet ", res);
    //             if (res.status) {
    //                 dispatch(clearAllBet());
    //                 setIsBettingFalse();
    //                 toast.success("Bet placed successfully")
    //                 setBetModal(true);
    //                 setTimeout(() => {
    //                     setBetModal(false);
    //                 }, 3000);
    //             } else {
    //                 setValueChangeModal(true)
    //             }
    //         });
    //     }
    // };

    const handlePlaceBet = async () => {
        console.log("first")
        setLoading(true);
        if (User == null) {
            setFunModeModal(true);
            setLoading(false);
        } else {
            // Check if all bet amounts are provided and valid
            const totalBetAmount = Object.values(inputValues).reduce((acc, val) => acc + parseFloat(val || 0), 0);
            const isAnyBetInvalid = Object.values(inputValues).some(val => !val || parseFloat(val) <= 0);

            if (isAnyBetInvalid) {
                toast.error("Please enter a valid amount for all bets");
                setLoading(false);
                return;
            }

            if (UserBalance < totalBetAmount) {
                setLowBalanceAlert(true);
                setLoading(false);
                return;
            }

            const bets = AllBets.map((item, index) => ({
                amount: inputValues[index],
                match_id: item.match_id,
                team_name: item.team_name,
                bet_title: item.seletedItem,
                return_amount: (inputValues[index] * item?.bhav).toFixed(2),
                tournament_name: "India Test",
                bhav: item.bhav,
                outcome_id: item.odd_id,
                sports_type: item.sports_type,
                market_id: item.market_id
            }));

            await createSportsBet({ bets }).then((res) => {
                setLoading(false);
                console.log("placing bet ", res);
                if (res.status) {
                    dispatch(clearAllSportsRadarBet());
                    setIsBettingFalse();
                    toast.success("Bet placed successfully");
                    setBetModal(true);
                    setTimeout(() => {
                        setBetModal(false);
                    }, 3000);
                } else {
                    setValueChangeModal(true);
                }
            });
        }
    };

    useEffect(() => {
        // setBet(Number(selectedBet));
    }, [selectedBet]);

    useEffect(() => {
        console.log(inputValues);
    }, [inputValues]);

    useEffect(() => {
        console.log(AllBets);
    }, [AllBets]);

    useEffect(() => {
        const initialInputValues = {};
        AllBets.forEach((item, index) => {
            initialInputValues[index] = item?.min || "";
        });
        setInputValues(initialInputValues);
    }, [AllBets]);

    const handleInputChange = (index, value) => {
        if (!isNaN(value)) {
            const numValue = parseFloat(value);
            const minValue = AllBets[index].min;
            const maxValue = AllBets[index].max;

            if (numValue > maxValue) {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    [index]: maxValue,
                }));
            } else {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    [index]: value,
                }));
            }
        }
    };

    return (
        <div
            className={`bg_clr_dark_blue_2 position-fixed pt-3 px-4 bet-slip ${active === true ? "bet-slip-active" : "bet-slip"
                }  w-100`}
        >
            <ToastContainer />
            <div className="container">
                <div>
                    <div
                        onClick={handlePlaceBet}
                        className="green-hr-line bg_clr_green mx-auto"
                    ></div>
                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex align-items-center gap-1">
                            <div className="bg_clr_dark_grey_3 px-2 py-1 ticket-br-radius d-flex align-items-center gap-1">
                                <img src={multiTicket} alt="ticket" />
                                <p className="ff_heebo fw-medium fs_11 text-white mb-0">Multi</p>
                            </div>
                        </div>
                        <div className="bg_clr_green px-2 py-1 ticket-br-radius d-flex align-items-center gap-1">
                            <img src={plus} alt="ticket" />
                            <p
                                className="ff_heebo fw-medium fs_11 text-white mb-0"
                                onClick={setIsBettingFalse}
                            >
                                Add More
                            </p>
                        </div>
                    </div>
                    <div className="">
                        {AllBets.map((item, index) => {
                            return (
                                <div className="bg_clr_dark_grey_3 py-2 mt-4 rounded-1">
                                    <div className="pt-1 pe-3 d-flex align-items-center justify-content-between">
                                        <div className="mb-0 text-white ff_inter fs_12 pb-1 ps-3">
                                            {item.seletedItem}
                                        </div>
                                        <div>
                                            <img
                                                src={cross}
                                                alt="cross"
                                                onClick={() => {
                                                    dispatch(deleteSportsRadarBet(index));
                                                    if (AllBets?.length == 1) {
                                                        setIsBettingFalse()
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="px-3">
                                        <p className="mb-0 text-white ff_inter fs_12 pb-1">
                                            {item.team_name}
                                        </p>
                                        <div className="events-hr-line w-100 "></div>
                                        <div className="text-white d-flex justify-content-between">
                                            <div>
                                                {item?.tournament_name}
                                            </div>
                                            <div>
                                                <div>{
                                                    inputValues[index] &&
                                                    <div>
                                                        Total Profit : {(inputValues[index] * item?.bhav).toFixed(2)}
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2 gap-4">
                                            <div className="search-input-div2 rounded-2 bg_clr_dark_blue_2 w-100 d-flex align-items-center px-3 py-1">
                                                <input
                                                    className="bg-transparent border-0 search-input ff_heebo fs_16 text-white opacity-26 w-100 py-0"
                                                    type="text"
                                                    placeholder="Enter amount to bet"
                                                    value={inputValues[index] || ""}
                                                    onChange={(e) =>
                                                        handleInputChange(index, e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bg_clr_green bet-placing-div px-3 d-flex align-items-center justify-content-center">
                                                    <p className="text-white mb-0 ff_inter fw-bold fs_12">
                                                        {item?.bhav}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="d-flex flex-column justify-content-between my-4">
                        <div
                            className="bg_clr_green bet-placing-div px-3 mt-3 py-4 d-flex align-items-center justify-content-center"
                            onClick={handlePlaceBet}
                            disabled={loading} // Disable button during loading
                        >
                            {loading ? (
                                <div className="spinner-border text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <p className="text-white mb-0 ff_inter fw-bold fs_12">Place Bet</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="bg_modal" show={lowbalance_alert} onHide={() => { setLowBalanceAlert(false) }} centered>
                <Modal.Body className="text-center  text-white">
                    <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
                        <div onClick={() => { setLowBalanceAlert(false) }} className=" position-absolute end-0 top-0">
                            <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
                        </div>
                        <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
                            Your Current Balance is low for place bet
                        </h3>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="bg_modal" show={FunModeModal} onHide={() => { setFunModeModal(false) }} centered>
                <Modal.Body className="text-center  text-white">
                    <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
                        <div onClick={() => { setFunModeModal(false) }} className=" position-absolute end-0 top-0">
                            <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
                        </div>
                        <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
                            Please Login First !
                        </h3>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="bg_modal" show={valueChangeModal} onHide={() => { setValueChangeModal(false) }} centered backdrop="static">
                <Modal.Body className="text-center  text-white">
                    <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
                        <div onClick={() => { setValueChangeModal(false) }} className=" position-absolute end-0 top-0">
                            <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
                        </div>
                        <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
                            Odd Value has been change please select new value to place bet or remove this bet to continue betting
                        </h3>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SportsRadarBettingModal;
