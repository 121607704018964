import {
  createSlice
} from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "User",
  initialState: {
    User: null,
    number: null,
    email: null,
    balance: 0,
    error: null,
    loading: false,
    bets: [],
    sportsRadarBets: [],
    total: 0,
  },
  reducers: {
    setNumber: (state, action) => {
      state.number = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setUser: (state, action) => {
      state.User = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },

    addBet: (state, action) => {
      state.bets.push(action.payload);
    },
    clearAllBet: (state, action) => {
      state.bets = [];
    },
    deleteBet: (state, action) => {
      state.bets = state.bets.filter((bet, index) => index !== action.payload);
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    addSportsRadarBet: (state, action) => {
      state.sportsRadarBets.push(action.payload);
    },
    clearAllSportsRadarBet: (state, action) => {
      state.sportsRadarBets = [];
    },
    deleteSportsRadarBet: (state, action) => {
      state.sportsRadarBets = state.sportsRadarBets.filter((bet, index) => index !== action.payload);
    },
  },
  extraReducers: (builder) => {
  },
});

export const { setNumber, setUser, setEmail, setBalance, addBet, deleteBet, setTotal, clearAllBet, addSportsRadarBet, clearAllSportsRadarBet, deleteSportsRadarBet } = UserSlice.actions;
export default UserSlice.reducer;
